import { createGlobalStyle, css } from "styled-components"

const OeNAProFontFaces = css`
  @font-face {
    font-family: "OE Na Pro";
    src: url("/fonts/OENaPro-Regular.woff2") format("woff2"),
      url("/fonts/OENaPro-Regular.woff") format("woff");
    font-weight: normal;
  }
  @font-face {
    font-family: "OE Na Pro";
    src: url("/fonts/OENaPro-Light.woff2") format("woff2"),
      url("/fonts/OENaPro-Light.woff") format("woff");
    font-weight: 300;
  }
  @font-face {
    font-family: "OE Na Pro";
    src: url("/fonts/OENaPro-SemiBold.woff2") format("woff2"),
      url("/fonts/OENaPro-SemiBold.woff") format("woff");
    font-weight: 600;
  }
  @font-face {
    font-family: "OE Na Pro";
    src: url("/fonts/OENaPro-Bold.woff2") format("woff2");
    font-weight: 700;
  }
`

const MazdaTypeFontFaces = css`
  @font-face {
    font-family: "Mazda Type";
    src: url("/fonts/MazdaType-Regular.woff2") format("woff2"),
      url("/fonts/MazdaType-Regular.woff") format("woff");
    font-weight: normal;
    unicode-range: U+0000-017F;
  }
  @font-face {
    font-family: "Mazda Type";
    src: url("/fonts/MazdaType-Medium.woff2") format("woff2"),
      url("/fonts/MazdaType-Medium.woff") format("woff");
    font-weight: 500;
    unicode-range: U+0000-017F;
  }
  @font-face {
    font-family: "Mazda Type";
    src: url("/fonts/MazdaType-Bold.woff2") format("woff2"),
      url("/fonts/MazdaType-Bold.woff") format("woff");
    font-weight: bold;
    unicode-range: U+0000-017F;
  }
`

const GlobalStyle = createGlobalStyle`
  ${OeNAProFontFaces}
  ${MazdaTypeFontFaces}

  :root {
    direction: rtl;
  }

  body {
    background-color: ${(p) => p.theme.colors.veryLightGrey};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  /* theme TouchRipple animation override */
  @keyframes MuiTouchRipple-keyframes-enter {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0.15;
    }
  }
`

export default GlobalStyle
