import { FormHelperText } from "@material-ui/core"
import React, { useEffect, useRef } from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import { verifyUser } from "../authSlice"
import ChangeNumberAndSendAgainButtons from "./ChangeNumberAndSendAgainButtons"
import CodeInput, { CodeInputRef } from "./CodeInput"
import CodeTimer from "./CodeTimer"

const StyledContainer = styled.div`
  text-align: center;
`
const StyledError = styled(FormHelperText).attrs({ error: true })`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
`

export const errorId = "otp-error"

const OTPForm = () => {
  const { error, loading } = useTypedSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  const codeInputRef = useRef<CodeInputRef>(null)

  // Reset the code when there's an error
  useEffect(() => {
    if (error) codeInputRef.current?.reset()
  }, [error])

  return (
    <StyledContainer>
      <CodeTimer />

      <CodeInput
        length={4}
        onComplete={async (code: string) => {
          await dispatch(verifyUser(code))
        }}
        ref={codeInputRef}
        disabled={loading}
      />

      <StyledError role="alert" id={errorId}>
        {error}
      </StyledError>

      <ChangeNumberAndSendAgainButtons
        focusCodeInput={() => codeInputRef.current?.focus?.()}
      />
    </StyledContainer>
  )
}

export default OTPForm
