import { TextField } from "@material-ui/core"
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import styled from "styled-components"
import { errorId } from "./OTPForm"

const StyledContainer = styled.div`
  direction: ltr;
`
const StyledTextField = styled(TextField)`
  width: 56px;
  height: auto;
  margin: ${(p) => p.theme.spacing(0, 0.5)};
  max-width: calc(25% - ${(p) => p.theme.spacing(1)}px);

  input {
    text-align: center;
    height: 40px;
  }
`

export type CodeInputRef = {
  reset: () => void
  focus: () => void
}

type CodeInputProps = {
  length: number
  disabled: boolean
  onComplete: (code: string) => Promise<void>
}

const CodeInput: ForwardRefRenderFunction<CodeInputRef, CodeInputProps> = (
  { length, disabled, onComplete },
  ref
) => {
  const initialCode = useMemo(() => Array<string>(length).fill(""), [length])

  const [code, setCode] = useState(initialCode)

  const inputsRef = useRef<HTMLInputElement[]>([])

  const focusInputAt = (index: number) => inputsRef.current[index].focus()

  const handleChange = (value: string, index: number) =>
    setCode((code) => {
      const newCode = [...code.slice(0, index), value, ...code.slice(index + 1)]

      const codeStr = newCode.join("")

      // If the code is fully entered, then call the `onComplete` callback;
      // otherwise, if the changed input isn't empty and isn't the last one,
      // then focus the next one
      if (codeStr.length === length) {
        onComplete(codeStr)
      } else if (value && index < length - 1) {
        focusInputAt(index + 1)
      }

      return newCode
    })

  const handleBackspace = (index: number) => {
    // Do nothing for the first input and for non-empty inputs
    if (index === 0 || code[index] !== "") return

    // Clear the previous digit/input
    setCode((code) => [...code.slice(0, index - 1), "", ...code.slice(index)])

    // Focus the previous input
    focusInputAt(index - 1)
  }

  useImperativeHandle(
    ref,
    () => ({
      reset: () => {
        // Reset the code & clear the input
        setCode(initialCode)

        // Focus first input
        focusInputAt(0)
      },
      focus: () => focusInputAt(0),
    }),
    [initialCode]
  )

  return (
    <StyledContainer
      role="group"
      aria-label="הזן קוד שנשלח אליך בסמס. הקוד בן 4 ספרות, תקף לדקה בלבד, בתום הדקה, באפשרותך ללחוץ על כפתור שליחה מחדש"
      aria-describedby={errorId}
    >
      {code.map((digit, i) => (
        <StyledTextField
          key={i}
          type="tel"
          inputProps={{ maxLength: 1, "aria-label": `סיפרה ${i + 1}` }}
          inputRef={(el) => (inputsRef.current[i] = el)}
          value={digit}
          onChange={(event) => handleChange(event.target.value, i)}
          onKeyDown={(event) => event.key === "Backspace" && handleBackspace(i)}
          onFocus={(event) => event.target.select()}
          autoFocus={i === 0}
          disabled={disabled}
        />
      ))}
    </StyledContainer>
  )
}

export default forwardRef(CodeInput)
