import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { trackPage } from "common/analytics"
import AuthDialog from "features/auth/AuthDialog"
import * as authRepository from "features/auth/authRepository"
import { initAuth } from "features/auth/authSlice"
import { fetchShowRooms } from "features/showRooms/showRoomsSlice"
import Layout from "layout/Layout"
import { DefaultSeo } from "next-seo"
import { AppProps } from "next/app"
import { Router, useRouter } from "next/router"
import React, { useEffect } from "react"
import { hotjar } from "react-hotjar"
import { useAppDispatch, wrapper } from "store"
import styled from "styled-components"
import theme from "theme/theme"
import ThemeProviders from "theme/ThemeProviders"

const ORIGIN = process.env.ORIGIN
if (!ORIGIN) {
  throw new Error("Please provide the ORIGIN environment variable.")
}

const AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY

const ENV = process.env.ENV

const HOTJAR_ID = process.env.HOTJAR_ID

Router.events.on("routeChangeComplete", (url: string) => {
  // let react router do the page tracking for the model page
  if (/^\/\d+/.test(url)) return
  trackPage()
  window.appInsights?.trackPageView()
})

const StyledEnvironmentName = styled.pre`
  background-color: ${theme.colors.red};
  color: ${theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999;
  margin: 0;
  opacity: 0.8;
  pointer-events: none;
`

const MyApp = ({ Component, pageProps }: AppProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector("#jss-server-side")
    jssStyles?.parentElement?.removeChild(jssStyles)

    // Initialize Azure Application Insights
    if (AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY && !window.appInsights) {
      window.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
        },
      })
      window.appInsights.loadAppInsights()
      window.appInsights.trackPageView()
    }

    // Initialize Hotjar
    if (HOTJAR_ID) {
      hotjar.initialize(parseInt(HOTJAR_ID), 6)
    }
  }, [])

  useEffect(() => {
    dispatch(initAuth(authRepository.getToken()))
    dispatch(fetchShowRooms())
  }, [dispatch])

  const { asPath } = useRouter()
  const url = (ORIGIN + asPath).split(/[?#]/)[0] // remove query string & hash

  return (
    <>
      <DefaultSeo
        titleTemplate="%s - מאזדה"
        canonical={url}
        openGraph={{
          site_name: "קנו מאזדה Online",
          locale: "he_IL",
          type: "website",
          url,
        }}
        additionalMetaTags={[
          { name: "theme-color", content: theme.colors.darkGrey },
        ]}
      />

      <ThemeProviders>
        <Layout>
          <Component {...pageProps} />

          <AuthDialog />
        </Layout>
      </ThemeProviders>

      {ENV && ENV !== "production" && (
        <StyledEnvironmentName>{ENV}</StyledEnvironmentName>
      )}
    </>
  )
}

export default wrapper.withRedux(MyApp)
