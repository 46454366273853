import { heIL } from "@material-ui/core/locale"
import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import { Shadows } from "@material-ui/core/styles/shadows"

const colors = {
  black: "#161616",
  white: "#ffffff",
  darkGrey: "#2b2b2b",
  grey: "#161616",
  lightGrey: "#d5d5d5",
  veryLightGrey: "#f5f5f5",
  red: "#920a2d",
  midGray: "#414141",
  backgroundGray: "#808080",
  yellow: "yellow",
}

const breakpoints = createBreakpoints({})

const muiThemeOptions: ThemeOptions = {
  direction: "rtl",
  palette: {
    primary: {
      main: colors.darkGrey,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.black,
    },
    text: {
      primary: colors.black,
      secondary: colors.grey,
    },
    error: {
      main: "#b50303",
    },
  },
  shadows: new Array(25).fill("none") as Shadows, // disable all shadows
  shape: { borderRadius: 2 },
  typography: {
    fontFamily: "Mazda Type, OE Na Pro, sans-serif",
    h2: {
      fontSize: "1.125rem",
      fontWeight: 600,
      letterSpacing: "0.72px",
      margin: "16px 0",

      [breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },
    h3: {
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: "0.56px",
      marginTop: "24px",
      marginBottom: "20px",
      borderBottom: `1px solid ${colors.black}`,
      paddingBottom: "8px",

      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    h4: {
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: "0.84px",
      marginTop: "20px",
      marginBottom: "8px",
      color: colors.grey,

      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
  },
  props: {
    MuiFormControl: {
      fullWidth: true,
    },
    MuiTextField: {
      fullWidth: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiLink: {
      underline: "always",
    },
  },
}

const muiTheme = createMuiTheme(muiThemeOptions, heIL)

muiThemeOptions.overrides = {
  MuiButton: {
    containedPrimary: {
      backgroundColor: colors.red,
      color: colors.white,
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: "0.64px",
      "&:hover": {
        backgroundColor: colors.red,
      },
      "@media (hover: none)": {
        "&&:hover": {
          backgroundColor: colors.red,
        },
      },
      "&:focus": {
        backgroundColor: colors.white,
        color: colors.red,
        outline: `2px solid ${colors.red}`,
      },
    },
    root: {
      "&:focus": {
        outline: "1px solid currentColor",
      },
    },
  },
  MuiIconButton: {
    colorSecondary: {
      backgroundColor: colors.backgroundGray,
      "&:hover": colors.backgroundGray,
    },
    root: {
      "&:hover": colors.backgroundGray,
    },
  },
  MuiTouchRipple: {
    rippleVisible: {
      opacity: 0.15,
      animation: `$enter 550ms ${muiTheme.transitions.easing.easeInOut}`,
    },
  },
  MuiInputLabel: {
    formControl: {
      position: "static",
    },
    shrink: {
      transform: "none",
    },
  },
  MuiFormLabel: {
    root: {
      color: colors.black,
      fontWeight: 600,
      letterSpacing: "0.16px",
      "&$error": {
        color: colors.black,
      },
      "& + .MuiInput-formControl": {
        marginTop: "6px",
      },
    },
    asterisk: {
      color: muiTheme.palette.error.main,
    },
  },
  MuiInput: {
    input: {
      border: `1px solid ${colors.grey}`,
      borderRadius: muiTheme.shape.borderRadius,
      height: "40px",
      boxSizing: "border-box",
      padding: muiTheme.spacing(1),
    },
    root: {
      "&$disabled": {
        color: colors.grey,
      },
    },
  },

  MuiFormControl: {
    root: {
      marginBottom: muiTheme.spacing(0.5),
    },
  },
  MuiFormHelperText: {
    root: {
      marginTop: 0,
    },
  },
  MuiDialog: {
    paper: {
      margin: "12px",
    },
  },
  MuiCheckbox: {
    colorPrimary: {
      "&$checked": {
        color: colors.red,
      },
    },
  },
}

const theme = {
  ...createMuiTheme(muiThemeOptions, heIL),
  colors,
}

export default theme

export type Theme = typeof theme
