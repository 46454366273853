import { Button, TextField as MuiTextField } from "@material-ui/core"
import { trackCheckoutStep } from "common/analytics"
import FormikCheckboxField from "common/FormikCheckboxField"
import UseFocusFirstInvalidField from "common/UseFocusFirstInvalidField"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-material-ui"
import React, { useEffect, useMemo, useRef } from "react"
import routes from "routes"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import * as yup from "yup"
import { register } from "../authSlice"
import ShowRoomSelect from "./ShowRoomSelect"

const StyledButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacing(3)}px;
`

export type PhoneFormValues = {
  name: string
  showRoom: number
  agreeMarketingOffers: boolean
}

const initialValues: PhoneFormValues = {
  name: "",
  showRoom: 0,
  agreeMarketingOffers: false,
}

const schema: yup.ObjectSchema<PhoneFormValues> = yup
  .object({
    name: yup
      .string()
      .required("יש להזין שם מלא")
      .matches(/([a-zA-Zא-ת'"\\.-/,].+\s).+/, "שם מלא לא תקין"),
    showRoom: yup.number().positive("יש לבחור אולם תצוגה").defined(),
    agreeMarketingOffers: yup.boolean().defined(),
  })
  .defined()

const RegisterForm = () => {
  const { temporaryPhone, loading } = useTypedSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  const phoneRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const showRoomRef = useRef<HTMLSelectElement>(null)
  const fieldNameInputRefMap = useMemo(
    () => ({
      phone: phoneRef,
      name: nameRef,
      showRoom: showRoomRef,
    }),
    []
  )

  useEffect(() => {
    if (location.hash === `#${routes.summary.path}`) trackCheckoutStep("3")
  }, [])

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(values) =>
        dispatch(
          register({
            fullName: values.name,
            showRoom: values.showRoom,
            agreeMarketingOffers: values.agreeMarketingOffers,
          })
        )
      }
    >
      {() => (
        <>
          <UseFocusFirstInvalidField
            fieldNameInputRefMap={fieldNameInputRefMap}
          />

          <Form noValidate aria-label="טופס הרשמה">
            <MuiTextField
              id="phone"
              label="מספר נייד"
              type="tel"
              required
              autoComplete="tel-national"
              helperText=" " // prevent a "jump" when an error appears
              inputProps={{ maxLength: 11, style: { direction: "ltr" } }}
              value={temporaryPhone}
              InputProps={{ readOnly: true }}
              inputRef={phoneRef}
            />

            <Field
              name="name"
              id="name"
              component={TextField}
              label="שם מלא"
              required
              autoFocus
              autoComplete="name"
              helperText=" " // prevent a "jump" when an error appears
              inputRef={nameRef}
            />

            <ShowRoomSelect inputRef={showRoomRef} />

            <FormikCheckboxField
              name="agreeMarketingOffers"
              label='הנני מאשר/ת קבלת פניות שיווקיות לדוא"ל, פקס, סמס מקבוצת דלק מוטורס ולהכלל במאגר המידע של הקבוצה'
            />

            {/* TODO: Use LoadingButton */}
            <StyledButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              title="לחץ להרשמה או לחץ על מקש ESC לסגירת החלונית"
            >
              הרשמה
            </StyledButton>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default RegisterForm
