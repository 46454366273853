import React, { useEffect, useState } from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import { setAllowSendingCodeAgain } from "../authSlice"

const StyledTimer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  font-size: 1.125rem;
`

const CodeTimer = () => {
  const [secondsLeft, setSecondsLeft] = useState(-1)

  const { codeExpirationInSeconds } = useTypedSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  // Update the seconds left when the code expiration changes (i.e when a new
  // code was sent)
  useEffect(() => {
    if (codeExpirationInSeconds) setSecondsLeft(codeExpirationInSeconds)
  }, [codeExpirationInSeconds])

  useEffect(() => {
    // If there are no seconds left then the user should be able to request to
    // send the code again
    if (secondsLeft === 0) {
      dispatch(setAllowSendingCodeAgain())
      return
    }

    const timeout = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000)

    return () => clearTimeout(timeout)
  }, [secondsLeft, dispatch])

  const minutes = `${Math.floor(secondsLeft / 60)}`.padStart(2, "0")
  const seconds = `${secondsLeft % 60}`.padStart(2, "0")

  return (
    <StyledTimer>
      {minutes}:{seconds}
    </StyledTimer>
  )
}

export default CodeTimer
