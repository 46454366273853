import { Dialog, DialogContent, Typography } from "@material-ui/core"
import CloseButton from "common/CloseButton"
import React from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import { AuthState, setOpen } from "./authSlice"
import OTPForm from "./otp/OTPForm"
import PhoneForm from "./phone/PhoneForm"
import RegisterForm from "./register/RegisterForm"

const StyledDialogContent = styled(DialogContent)`
  position: relative;
  padding: ${(p) => p.theme.spacing(3)}px;

  :first-child {
    padding-top: ${(p) => p.theme.spacing(3)}px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    padding: ${(p) => p.theme.spacing(3, 6)};
  }
`
const StyledHeading = styled(Typography).attrs({ variant: "h2" })`
  text-align: center;
  margin-top: 0;
  margin-bottom: ${(p) => p.theme.spacing(3.5)}px;
`

type StepMap<T> = { [step in AuthState["step"]]: T }

const stepTitleMap: StepMap<string> = {
  Phone: "התחברות",
  OTP: "הזן קוד שנשלח אליך בסמס",
  Register: "הרשמה",
}

const formMap: StepMap<React.ComponentType> = {
  Phone: PhoneForm,
  OTP: OTPForm,
  Register: RegisterForm,
}

const AuthDialog = () => {
  const { open, step } = useTypedSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  const Content = formMap[step]

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(setOpen(false))}
      aria-label="התחברות"
      maxWidth="xs"
      disableRestoreFocus
    >
      <StyledDialogContent>
        <CloseButton onClick={() => dispatch(setOpen(false))} />

        <StyledHeading>{stepTitleMap[step]}</StyledHeading>

        <Content />
      </StyledDialogContent>
    </Dialog>
  )
}

export default AuthDialog
