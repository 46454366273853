import { Button } from "@material-ui/core"
import React from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import { backToPhoneStep, generateVerificationCode } from "../authSlice"

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing(2)}px;
`
const StyledButtonContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`
const StyledButton = styled(Button)`
  color: ${(p) => p.theme.palette.text.secondary};
  text-decoration: underline;

  :hover {
    text-decoration: underline;
  }
`
const StyledDivider = styled.span.attrs({ children: "|" })`
  color: ${(p) => p.theme.palette.text.secondary};
  font-weight: bold;
  position: relative;
  top: 3px;
  margin: ${(p) => p.theme.spacing(1, 1, 0)};
`

type ChangeNumberAndSendAgainButtonsProps = {
  focusCodeInput: () => void
}

const ChangeNumberAndSendAgainButtons = ({
  focusCodeInput,
}: ChangeNumberAndSendAgainButtonsProps) => {
  const { loading, allowSendingCodeAgain } = useTypedSelector(
    (state) => state.auth
  )

  const dispatch = useAppDispatch()

  return (
    <StyledContainer>
      <StyledButtonContainer style={{ textAlign: "left" }}>
        <StyledButton
          onClick={() => dispatch(backToPhoneStep())}
          disabled={loading}
        >
          החלפת מס&apos; נייד
        </StyledButton>
      </StyledButtonContainer>

      <StyledDivider />

      <StyledButtonContainer style={{ textAlign: "right" }}>
        <StyledButton
          onClick={async () => {
            await dispatch(generateVerificationCode())

            focusCodeInput()
          }}
          aria-pressed={loading || !allowSendingCodeAgain}
          disabled={loading || !allowSendingCodeAgain}
        >
          שלחו לי שוב
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  )
}

export default ChangeNumberAndSendAgainButtons
