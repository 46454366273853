import { Button, Typography } from "@material-ui/core"
import { trackCheckoutStep } from "common/analytics"
import UseFocusFirstInvalidField from "common/UseFocusFirstInvalidField"
import { Field, Form, Formik, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import React, { useEffect, useMemo, useRef } from "react"
import routes from "routes"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import * as yup from "yup"
import { generateVerificationCode } from "../authSlice"

const StyledButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacing(1)}px;
`

const schema = yup
  .object({
    phone: yup
      .string()
      .required("יש להזין מספר נייד")
      .matches(/^05\d-?\d{7}$/, "מספר נייד שגוי"),
  })
  .defined()

const initialValues: yup.InferType<typeof schema> = { phone: "" }

const PhoneForm = () => {
  const { loading, temporaryPhone } = useTypedSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  const bagRef = useRef<FormikProps<typeof initialValues>>()

  const phoneRef = useRef<HTMLInputElement>(null)

  const fieldNameInputRefMap = useMemo(() => ({ phone: phoneRef }), [])

  useEffect(() => {
    if (temporaryPhone) bagRef.current?.setFieldValue("phone", temporaryPhone)
  }, [temporaryPhone])

  useEffect(() => {
    if (location.hash === `#${routes.summary.path}`) trackCheckoutStep("2")
  }, [])

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        await dispatch(generateVerificationCode(values.phone))
      }}
      innerRef={bagRef as any}
    >
      {() => (
        <>
          <UseFocusFirstInvalidField
            fieldNameInputRefMap={fieldNameInputRefMap}
          />

          <Form noValidate>
            <Field
              name="phone"
              id="phone"
              component={TextField}
              label={
                <>
                  <Typography variant="srOnly">התחברות, </Typography>
                  מספר נייד
                </>
              }
              type="tel"
              required
              autoComplete="tel-national"
              helperText=" " // prevent a "jump" when an error appears
              inputProps={{ maxLength: 11, style: { direction: "ltr" } }}
              inputRef={phoneRef}
            />

            {/* TODO: Use LoadingButton */}
            <StyledButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              קבלת קוד בסמס
            </StyledButton>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default PhoneForm
